import React, { useState } from 'react'
import './nav.css'
import {TbHome} from 'react-icons/tb'
import {TbUser} from 'react-icons/tb'
import {TbBook} from 'react-icons/tb'
import {TbMail} from 'react-icons/tb'
import {AiFillFolderOpen} from 'react-icons/ai'
import {FaLaptopCode} from 'react-icons/fa'






const Nav = () => {
  const [activeNav, setActiveNav] = useState ('#')
  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><TbHome/></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><TbUser/></a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><FaLaptopCode/></a>
      <a href="#portfolio" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><AiFillFolderOpen/></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><TbMail/></a>
    </nav>
  )
}

export default Nav