import React from 'react'
import './contact.css'
import {TbMail} from 'react-icons/tb'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'



const Contact = () => {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_o62tmys', 'template_tumenv7', form.current, '4b_4jypnjKZlx6kae')

      e.target.reset()
      alert('Sent Successfully')
  };


  return (
    <section id='contact'>
      <h5>Let's Talk</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          {/*<article className="contact__option">
            <TbMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>alisagulamhussein@gmail.com</h5>
            <a href="mailto:alisagulamhussein@gmail.com" target='_blank'>Email Me</a>
            </article>*/}

          <article className="contact__option" data-aos="flip-left" data-aos-duration="2000">
            <BsGithub className='contact__option-icon'/>
            <h4>Github</h4>
            <h5>alisaimtiaz</h5>
            <a href="https://github.com/alisaimtiaz" target='_blank'>My Profile</a>
          </article>

          <article className="contact__option" data-aos="flip-left" data-aos-duration="2000">
            <BsLinkedin className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            <h5>alisa g</h5>
            <a href="https://www.linkedin.com/in/alisa-g-140715150/" target='_blank'>My Profile</a>
          </article>
        </div>
        {/*end of contact options*/}

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Name' required />
          <input type="text" name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary' data-aos="zoom-in" data-aos-duration="2000">Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact