import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/catsticker.gif'
import HeaderSocials from './HeaderSocials'




const Header = () => {
  return (
    <header>
      
      <div className="container header__container">
        <h5 className='firsth5'>hello, i'm</h5>
        <h1>alisa</h1>
        <h5 className="text-light">front end developer</h5>

        <CTA/>
        <div className="me" >
          <img src={ME} alt="img" />
        </div>
        
        
        <HeaderSocials/>

        
        

        <a href="#contact" className='scroll__down'>To Bottom</a>
      </div>
    </header>
  )
}

export default Header