import React from 'react'
import './about.css'
import ME from '../../assets/cat-code.gif'
import {TbBrandHtml5} from 'react-icons/tb'
import {TbBrandCss3} from 'react-icons/tb'
import {TbBrandJavascript} from 'react-icons/tb'






const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know Me</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <img src={ME} alt="About Image" />
        </div>

        <div className="about__content">
          <div className="about__cards" data-aos="fade-left" data-aos-duration="1000">
            
            <article className='about__card'>
              <TbBrandHtml5 className='about__icon'/>
              <h5>HTML</h5>
              <small>2+ years</small>
            </article>

            <article className='about__card'>
              <TbBrandCss3 className='about__icon'/>
              <h5>CSS</h5>
              <small>2+ years</small>
            </article>

            <article className='about__card'>
              <TbBrandJavascript className='about__icon'/>
              <h5>JavaScript</h5>
              <small>1+ years</small>
            </article>

          </div>

          <p>
          As an innovative web developer specializing in crafting digital experiences for enterprises, 
          my skills include Adobe Creative Suite, HTML, CSS, JavaScript, SQL, and C++.
          I transform ideas into captivating online solutions that drive results.
          <br/><br/> Logic is my framework - Imagination is my edge.
          </p>
          <a href="#contact" className='btn btn-primary' data-aos="zoom-in" data-aos-duration="1000">Contact Me</a>
        </div>
      </div>
      
    </section>
    
  )
  
}


export default About