import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/Man Vs Fries Thumbnail.jpg'
import IMG2 from '../../assets/Earl of Sandwich Thumbnail.png'
import IMG3 from '../../assets/Brio Italian Grille Thumbnail.png'
import IMG4 from '../../assets/Buca di Beppo Thumbnail.png'
import IMG5 from '../../assets/student.JPG'
import IMG6 from '../../assets/desk.JPG'


const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Man Vs Fries',
    github: 'https://github.com/Earl-Enterprises',
    demo: 'https://www.behance.net/gallery/192584837/Man-Vs-Fries'
  },

  {
    id: 2,
    image: IMG2,
    title: 'Earl of Sandwich',
    github: 'https://github.com/Earl-Enterprises',
    demo: 'https://www.behance.net/gallery/192584437/Earl-of-Sandwich'
  },

  {
    id: 3,
    image: IMG3,
    title: 'Brio Italian Grille',
    github: 'https://github.com/Earl-Enterprises',
    demo: 'https://www.behance.net/gallery/192584229/Brio-Italian-Grille'
  },

  {
    id: 4,
    image: IMG4,
    title: 'Buca di Beppo',
    github: 'https://github.com/Earl-Enterprises',
    demo: 'https://www.behance.net/gallery/192583907/Buca-di-Beppo-Italian-Restaurant'
  },

  {
    id: 5,
    image: IMG5,
    title: 'Print Designs',
    github: 'https://github.com/alisaimtiaz',
    demo: 'https://www.behance.net/gallery/192581593/Print-Designs'
  },

  {
    id: 6,
    image: IMG6,
    title: 'Digital Designs',
    github: 'https://github.com/alisaimtiaz',
    demo: 'https://www.behance.net/gallery/192582497/Digital-Designs'
  }
]



const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container" data-aos="fade-up"
     data-aos-duration="3000">
        {
        data.map(({id, image, title, github, demo}) => {
          return(
            <article key={id} className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={image} alt={title} />
          </div>

          <h3>{title}</h3>
          <div className="portfolio__item-cta">
            <a href={github} className='btn' target='_blank'>Github</a>
            <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
          )
        })
      }
      </div>
    </section>
  )
}

export default Portfolio