import React from 'react'
import './experience.css'
import {TbSquareRoundedCheckFilled} from 'react-icons/tb'


const Experience = () => {
  return (
    <section id='experience'>
      <h5>Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">

        <div className="experience__frontend" data-aos="fade-up-right" data-aos-duration="1500">
          <h3>Frontend Development</h3>
          <div className="experience__content">

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Professional</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Professional</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>SQL</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>C++</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>React</h4>
                <small className='text-light'>Professional</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>Bootstrap</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>jQuery</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

          </div>
        </div>
        {/*END OF FRONTEND*/}


        <div className="experience__backend" data-aos="fade-up-left" data-aos-duration="1500">
          <h3>UX/UI Design</h3>
          <div className="experience__content">

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>Adobe Creative Suite</h4>
                <small className='text-light'>Professional</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>Color Theory</h4>
                <small className='text-light'>Professional</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>Project Management</h4>
                <small className='text-light'>Professional</small>
              </div>
            </article>

            <article className='experience__details'>
              <TbSquareRoundedCheckFilled className='experience__details-icon'/>
              <div>
                <h4>Agile/SCRUM</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>



          </div>
        </div>
          
        </div>

      
    </section>
  )
}

export default Experience